const ENV = process.env.NODE_ENV;

const config = {
  development: {
    API_URL: "http://localhost:3000",
    ALUNO_URL: "http://localhost:3001",
    ADMIN_URL: "http://localhost:3002",
    SITE_URL: "http://localhost:3003",
    NURALUTA_API: "http://localhost:8080",
  },
  production: {
    API_URL: "https://apm-api.herokuapp.com",
    ALUNO_URL: "https://aluno.academiapedromartinez.com.br",
    ADMIN_URL: "https://admin.academiapedromartinez.com.br",
    SITE_URL: "https://www.academiapedromartinez.com.br",
    NURALUTA_API: "https://nura-bjj-api-c848f5f4dd05.herokuapp.com",
  },
};

export default config[ENV];
